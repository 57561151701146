import * as React from "react"
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image';
import Layout from "../components/layout";
import {content, dateContainer, imageContainer} from '../styles/post.module.scss'


const IndexPage = ({data}) => {
    const {html, frontmatter} = data.post;
    const convertedDate = new Intl.DateTimeFormat('fa-IR').format(new Date(frontmatter.date));
    return (
        <Layout pageTitle="Home">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="description" content={frontmatter.title}/>
                <title>امیر‌افسری</title>
                <link rel="canonical" href="https://amirafsari.com/"/>
            </Helmet>
            <div className={content}>
                <h1>{frontmatter.title}</h1>
                <div className={dateContainer}>
                    <time dateTime={frontmatter.date}>{convertedDate}</time>
                </div>
                <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} className={imageContainer}/>
                <div dangerouslySetInnerHTML={{__html: html}}/>
            </div>
        </Layout>
    )
};


export const query = graphql`
  query PostQuery($id: String) {
    post: markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        slug
        title
        date
        author
        topic
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;


export default IndexPage
